import { Component, OnInit, EventEmitter, Output} from '@angular/core';
import {AuthService} from "../../services/auth.service";
import jwt_decode from "jwt-decode";
import {JWT} from "../../model/jwt.model";
import {LoadingService} from "../../services/loading.service";
import {InvoiceService} from "../../services/invoice.service";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit{
  constructor(private authService:AuthService) { }

  loggedIn = false;
  isBillable = false;
  isMenuOpen = false;

  ngOnInit() {
    this.refreshToken();
    this.authService.loginSuccess$.subscribe(() => this.refreshToken());
    this.authService.logoutSuccess$.subscribe(() => {
      this.refreshToken();
      // window.location.reload();
    });
  }
  private async refreshToken() {
    this.loggedIn = await this.authService.isLoggedIn();
    if(this.loggedIn){

      let cookieValue = await this.authService.getToken();

      if(!cookieValue) {
        console.log("no cookie found")
        return;
      }
      let decodedCookie = jwt_decode<JWT>(cookieValue);
      if (decodedCookie['cognito:groups']?.includes("billable") ?? false) {
        this.isBillable = true;
      } else {
        console.log("has no active subscription")
      }
    }
  }

  logout(){
    this.authService.logout();
  }

  toggleMenu() {
    const menu = document.querySelector('.menu-items');
    menu?.classList.toggle('menu-open');
    menu?.classList.toggle('menu-closed');
  }

  closeMenu() {
    const menu = document.querySelector('.menu-items');
    menu?.classList.add('menu-closed');
    menu?.classList.remove('menu-open');
  }
}
