import {Component, ViewChild} from '@angular/core';
import {Session} from 'src/model/session.model';
import {PatientService} from '../../services/patient.service';
import {SessionService} from "../../services/session.service";
import {Patient} from "../../model/patient.model";
import {SessionComponent} from '../sessions/sessions.component';
import {MatDialog} from '@angular/material/dialog';
import {ConfirmDialogComponent} from '../confirm-dialog/confirm-dialog.component';
import {AppointmentComponent} from "../appointments/appointments.component";


@Component({
  selector: 'app-patient-list',
  templateUrl: './patient-list.component.html',
  styleUrls: ['./patient-list.component.scss']
})
export class PatientListComponent {

  patients: Patient[] = [];
  selectedPatient?: Patient;
  editingPatient: Patient = this.getEmptyPatient();
  isCreating: boolean = false;
  isEditing: boolean = false;
  sessions: Session[] = [];

  @ViewChild(SessionComponent) sessionComponent?: SessionComponent;
  @ViewChild(AppointmentComponent) appointmentComponent?: AppointmentComponent;


  constructor(private patientService: PatientService,
              private sessionService: SessionService,
              private dialog: MatDialog) {
  }

  searchPatients(name: string): void {
    if (name.length > 2 || name === '*') {
      this.patientService.searchPatients(name).subscribe(patients => this.patients = patients);
    } else {
      this.patients = [];
    }
  }

  selectPatient(patient: Patient): void {
    this.selectedPatient = patient;
    this.isEditing = false;
    this.isCreating = false;


    this.sessionService
      .getSessions(patient.patientId, 0, 20)
      .subscribe({
        next: sessions => {
          this.sessions = sessions;
        },
      });
  }

  unselectPatient() {
    this.selectedPatient = undefined;
    this.sessions = [];
    this.isEditing = false;
    this.isCreating = false;
  }

  startCreating(): void {
    this.isCreating = true;
    this.isEditing = false;
    this.selectedPatient = undefined;
    this.editingPatient = this.getEmptyPatient();
  }

  startEditing(): void {
    this.isEditing = true;
    this.editingPatient = <Patient>{...this.selectedPatient};
  }

  deletePatient(): void {
    if (!this.selectedPatient) return;

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: `Möchten Sie wirklich den Patienten ${this.selectedPatient.firstname} ${this.selectedPatient.lastname} löschen?`,
    });

    dialogRef.afterClosed().subscribe(result => {
      if (this.selectedPatient && result) {
        this.patientService
          .deletePatient(this.selectedPatient.patientId)
          .subscribe({
            next: _ => {
              this.patients = this.patients.filter(p => p.patientId !== this.selectedPatient?.patientId);
              this.selectedPatient = undefined;
            },
          });
      }
    });
  }

  savePatient(patient: Patient): void {
    if (this.isCreating) {
      this.patientService
        .createPatient(patient)
        .subscribe({
          next: newPatient => {
            this.patients.push(newPatient);
            this.isCreating = false;
          },
        });
    } else if (this.isEditing && this.selectedPatient) {
      this.patientService
        .updatePatient(this.selectedPatient.patientId, patient)
        .subscribe({
          next: updatedPatient => {
            const index = this.patients.findIndex(p => p.patientId === this.selectedPatient?.patientId);
            this.patients[index] = updatedPatient;
            this.selectedPatient = undefined;
            this.isEditing = false;
          },
        });
    }
  }

  cancelEditOrCreate(): void {
    this.isEditing = false;
    this.isCreating = false;
    this.selectedPatient = undefined; // Setze selectedPatient zurück oder passe dies nach Bedarf an
    this.editingPatient = this.getEmptyPatient(); // Lösche die Formulardaten
  }

  startNewSession(): void {
    this.sessionComponent?.initiateCreateSession();
  }

  startNewAppointment() {
    this.appointmentComponent?.initiateCreateAppointment();
  }

  getEmptyPatient(): Patient {
    return {patientId: '', firstname: '', lastname: '', birthdate: '', sv_nr: '', address: '', email: '', phone: ''};
  }

  calculateAge(birthdate: string): number {
    const birthDate = new Date(birthdate);
    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  }
}
