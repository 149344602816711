import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../environments/environment';
import {from, Observable, switchMap} from 'rxjs';
import {LoadingService} from "./loading.service";
import {Appointment} from "../model/appointment.model";
import {tap} from "rxjs/operators";
import {AuthService} from "./auth.service";
import {BaseService} from "./base.service";

@Injectable({
  providedIn: 'root'
})
export class AppointmentService extends BaseService {

  constructor(private http: HttpClient,
              override loadingService: LoadingService,
              override authService: AuthService) {
    super(loadingService, authService);
  }

  private getAppointmentsUrl(patientId: string) {
    return `${environment.apiUrl}/patients/${patientId}/appointments`
  }

  getAppointments(patientId: string | undefined, page: number = 0, pageSize: number = 20): Observable<Appointment[]> {
    if (!patientId) throw new Error('Patient ID is required to create a appointment');
    const params = {
      page,
      pageSize
    };
    return this.newRequest(
      options =>
        this.http.get<Appointment[]>(this.getAppointmentsUrl(patientId), {
          ...options,
          params
        }),
      error =>
        this.handleError("Failed to load appointments", error));
  }

  getAppointment(patientId: string | undefined, appointmentId: string): Observable<Appointment> {
    if (!patientId) throw new Error('Patient ID is required to create a appointment');
    return this.newRequest(
      options =>
        this.http.get<Appointment>(`${this.getAppointmentsUrl(patientId)}/${appointmentId}`, options),
      error =>
        this.handleError("Failed to load appointment", error));
  }

  createAppointment(patientId: string | undefined, appointment: any): Observable<any> {
    if (!patientId) throw new Error('Patient ID is required to create a appointment');
    return this.newRequest(
      options =>
        this.http.post<Appointment>(this.getAppointmentsUrl(patientId), appointment, options),
      error =>
        this.handleError("Failed to update appointment", error));
  }

  updateAppointment(patientId: string | undefined, appointmentId: string, appointment: any): Observable<any> {
    if (!patientId) throw new Error('Patient ID is required to create a appointment');
    return this.newRequest(
      options =>
        this.http.put<Appointment>(`${this.getAppointmentsUrl(patientId)}/${appointmentId}`, appointment, options),
      error =>
        this.handleError("Failed to update appointment", error));
  }

  deleteAppointment(patientId: string | undefined, appointmentId: string): Observable<any> {
    if (!patientId) throw new Error('Patient ID is required to create a appointment');
    return this.newRequest(
      options =>
        this.http.delete(`${this.getAppointmentsUrl(patientId)}/${appointmentId}`, options),
      error =>
        this.handleError("Failed to delete appointment", error)
    );
  }

}
