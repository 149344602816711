import {HttpHeaders} from "@angular/common/http";
import {LoadingService} from "./loading.service";
import {AuthService} from "./auth.service";
import {from, Observable, switchMap} from "rxjs";
import {tap} from "rxjs/operators";

export abstract class BaseService {
  protected constructor(
    protected loadingService: LoadingService,
    protected authService: AuthService,
  ) {

  }

  protected newRequest(httpCall: (headers: {
    headers: HttpHeaders
  }) => Observable<any>, errorHandler: (error: any) => void) {
    this.loadingService.startLoading();
    return from(this.authService.getHttpOptions())
      .pipe(
        switchMap(options => httpCall(options)),
        tap({
          error: error => {
            this.loadingService.stopLoading();
            errorHandler(error);
          },
          complete: () => this.loadingService.stopLoading(),
        })
      );
  }

  protected handleError(message: string, error: any) {
    console.error(message, error);
    this.authService.handleAuthenticationError(error);
  }
}
