import {Environment} from "../model/environment.model";

const authDomain = 'https://app.dev.einfach-behandeln.ch'

export const environment = <Environment>{
  production: true,
  apiUrl: 'https://api.dev.einfach-behandeln.ch',
  userPoolId: 'eu-central-2_L9NUU5kmR',
  userPoolWebClientId: '3g6h19rppd5qqfns09cr92egjl',
  oauthDomain: authDomain,
  oauthRedirectSignIn: [`${authDomain}/login`],
  oauthRedirectSignOut: [`${authDomain}/logout`],
  stripeConfig : {
    pricingTableId: "prctbl_1P7J61P1DrplaOYjPGVwxvHX",
    publishableKey:"pk_test_51P7HLkP1DrplaOYjGcZjn43LSDxbGNsCIvtcZqCEgeIIoZql0jf072g1EIiR4qINT1hm9rV63U7uzd62gOMkkFt100h9YAIFlo",
    customerPortal: "https://billing.stripe.com/p/login/test_cN29DPeZhcjZaukaEE",
  }
};
