<div class="container home-page">
    <div class="side-by-side-container" *ngIf="isLoggedIn">
        <div class="left">
            <h2>Willkommen</h2>
            <p>Willkommen bei "Einfach Behandeln"</p>
        </div>
    </div>
    <div class="side-by-side-container" *ngIf="isSubscribed">
      <div class="left">
        <h2>Abonnement</h2>
        <p>Sie haben ein gültiges Abonnement</p>
      </div>
    </div>
    <div *ngIf="!isLoggedIn" class="logIn">
        <div>
            <h2>Sie sind nicht angemeldet.</h2>
          <amplify-authenticator>
            <ng-template
              amplifySlot="authenticated"
              let-user="user"
              let-signOut="signOut"
            >
              <h1>Welcome {{ user.username }}!</h1>
              <button (click)="signOut()">Sign Out</button>
            </ng-template>
          </amplify-authenticator>
        </div>
    </div>
</div>
