<div class="container appointments">
  <h2>Termine</h2>
  <div *ngIf="selectedAppointment || isCreating">
    <h3>{{isCreating ? 'Neuen Termin anlegen' : 'Termin Bearbeiten'}}</h3>
    <form [formGroup]="appointmentForm">
      <div>
        <label for="title">Titel</label>
        <input id="title" type="text" formControlName="title">
      </div>
      <div>
        <label for="description">Beschreibung</label>
        <input id="description" type="text" formControlName="description">
      </div>
      <div>
        <label for="date">Datum</label>
        <input id="date" type="date" formControlName="date">
      </div>
      <div>
        <label for="time">Uhrzeit</label>
        <input id="time" type="time" formControlName="time">
      </div>
      <div>
        <label for="duration">Dauer</label>
        <input id="duration" type="number" formControlName="duration">
      </div>
      <div>
        <button type="submit" (click)="isCreating ? createAppointment() : updateAppointment()">{{isCreating ? 'Termin erstellen' : 'Termin aktualisieren'}}</button>
        <button type="button" (click)="cancel()">Abbrechen</button>
      </div>
    </form>
  </div>

  <div *ngFor="let appointment of appointments">
    <h3>{{ appointment.date | date:'dd.MM.yyyy HH:mm' }} {{ appointment.title }} ({{ appointment.duration }} Minuten)</h3>
    <p>{{ appointment.description }}</p>
    <button (click)="selectAppointment(appointment)">Bearbeiten</button>
    <button (click)="deleteAppointment(appointment)">Löschen</button>
  </div>


  <div>
    <button (click)="initiateCreateAppointment()">Neuen Termin erstellen</button>
  </div>
</div>
