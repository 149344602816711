import {Component, OnInit} from '@angular/core';
import jwt_decode from 'jwt-decode';
import {JWT} from "../../model/jwt.model";
import {AuthService} from "../../services/auth.service";

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss']
})

export class HomePageComponent implements OnInit {

  isSubscribed = false;
  isLoggedIn = false;

  //  Read from cookie named token
  constructor(private authService: AuthService) {
  }

  ngOnInit() {
    this.refreshToken();
    this.authService.loginSuccess$.subscribe(() => this.refreshToken());
    this.authService.logoutSuccess$.subscribe(() => this.refreshToken());
  }

  private async refreshToken() {
    this.isLoggedIn = await this.authService.isLoggedIn();
    if(this.isLoggedIn){
      let cookieValue = await this.authService.getToken();

      if(!cookieValue) {
        console.log("no cookie found")
        this.isSubscribed = false;
        return;
      }

      console.log(jwt_decode(cookieValue))
      let decodedCookie = jwt_decode<JWT>(cookieValue);
      console.log(decodedCookie['cognito:groups']);

      if (decodedCookie['cognito:groups']?.includes("billable") ?? false) {
        console.log("is subscribed user")
        this.isSubscribed = true;
      } else {
        console.log("has no active subscription")
      }
    } else {
      this.isSubscribed = false;
    }
  }
}
