import {Injectable} from '@angular/core';
import jsPDF, {DocumentProperties} from 'jspdf';
import 'jspdf-autotable'

interface GenInvoiceData {

}

interface PatientData {
  name: string;
  firstName: string;
  address: string;
  zipCode: string;
  city: string;
  birthDate: string;
  gender: string;
}

interface ServicePosition {
  date: string;
  tariffNumber: number;
  quantity: number;
  amount: number;
  description: string;
}

class InvoiceDocument {
  //TODO set as private
  public doc: jsPDF;

  // Set font sizes
  private titleFontSize = 15;
  private headerFontSize = 7;
  private contentFontSize = 8;
  private borderWidth = 0.01;

  // Page Margins
  private margin_top = 1;
  private margin_bottom = 1.7;
  private margin_left = 1.5;
  private margin_right = 1.5;

  //position of address label
  private addressLabelX = 10.3;
  private addressLabelY = 4.5;

  constructor() {
    this.doc = new jsPDF({
      orientation: "portrait",
      unit: "cm", //all position or space units are cm (0.1 = 1mm)
      format: "a4"
    });
    this.updateDocumentProperties();
  }

  setMargins(top: number, bottom: number, left: number, right: number) {
    this.margin_top = top;
    this.margin_bottom = bottom;
    this.margin_left = left;
    this.margin_right = right;
    this.updateDocumentProperties();
  }

  /**
   * The main title of the invoice/reminder print template for a Tiers Garant case. Note that the processing attributes are reflected by prefixes to the title.
   *
   * Tiers Garant t depending on the type { invoice | reminder }:
   *   de:  { Rückforderungsbeleg | n. Mahnung }
   *   fr:   { Justificatif de remboursement  | n. Rappel }
   *   it:    { Giustificativo per la richiesta di rimborso | Richiamo }
   *   where n is between 1 and 3
   *
   *  If the copy flag is set the following prefix is prepended to the title:
   *   de: Kopie:
   *   fr:  Copie:
   *   it:   Copia:
   *
   * If the storno flag is set the prefix Storno: is prepended to the title.
   * @param title
   * @param version
   */
  setTitle(title: string, version: string) {

    //Dokument Title
    this.doc.setFontSize(this.titleFontSize);
    this.doc.text(title, this.margin_left, this.margin_top + this.fontSizeInCm(this.titleFontSize));

    //Document Version
    const documentWidth = this.doc.internal.pageSize.getWidth(); // Get the width of the document
    this.doc.setFontSize(9); // Set the font size for the version
    const textSize = this.doc.getTextWidth(version); // Get the width of the text
    const textPositionX = documentWidth - this.margin_right - textSize; // Calculate the x position of the text
    this.doc.text(version, textPositionX, this.margin_top + this.fontSizeInCm(this.titleFontSize));
  }


  setMetadata(metadata: {
    provider : {
      name: string,
      address: string,
      zipCode: string,
      city: string,
    },
    patient : {
      name: string,
      firstName: string,
      address: string,
      zipCode: string,
      city: string,
      birthdate: string,
    }
  }) {
    // Daten für die Tabellen definieren (leere Inhalte, da der Fokus auf dem Rahmen liegt)
    const bodyData1 = [
      ["Dokument", "Identifikation", "1472724906", "", "", "Seite: 1"],
      [{
        content: "Rechnungs-\nsteller",
        rowSpan: 2
      }, "GLN-Nr. (B)", "2011234567890", `${metadata.provider.name} `, "Tel: ", "061 956 99 00"],
      ["ZSR-Nr. (B)", "H121111", `${metadata.provider.address} ${metadata.provider.zipCode} ${metadata.provider.city}`, "Fax:", "061 956 99 10"],
      [{
        content: "Leistungs-\nerbringer",
        rowSpan: 2
      }, "GLN-Nr. (P)", "7634567890111", `${metadata.provider.name} `, "Tel: ", "061 956 99 00"],
      ["ZSR-Nr. (P)", "P123456", `${metadata.provider.address} ${metadata.provider.zipCode} ${metadata.provider.city}`, "Fax:", "061 956 99 10"],
    ];

    const bodyData2 = [
      [{content: "Patient", rowSpan: 21},
       "Name", `${metadata.patient.name}`, "", "GLN-Nr", "-"],
      ["Vorname", `${metadata.patient.firstName}`, "", "", ""],
      ["Strasse", `${metadata.patient.address}`, "", "", ""],
      ["PLZ", `${metadata.patient.zipCode}`, "", "", ""],
      ["Ort", `${metadata.patient.city}`, "", "", ""],
      ["Geburtsdatum", `${metadata.patient.birthdate}`, "", "", ""],
      ["Geschlecht", "F", "", "", ""],
      ["Falldatum", "x", "", "", ""],
      ["Fall-Nr", "x", "", "", ""],
      ["AHV-Nr", "x", "", "", ""],
      ["VEKA-Nr", "x", "", "", ""],
      ["Versicherten-Nr", "x", "", "", ""],
      ["Kanton", "x", "", "", ""],
      ["Kopie", "x", "", "", ""],
      ["Vergütungsart", "x", "", "", ""],
      ["Gesetz", "x", "", "", ""],
      ["Behandlung", "x", "", "", ""],
      ["Behandlungsart", "x", "", "", ""],
      ["Behandlungsgrund", "x", "", "", ""],
      ["Betriebs-Nr", "x", "", "", ""],
      ["Rolle/Ort", "x", "", "", ""],
    ];
    const bodyData3 = [
      ["Zuweiser", "GLN-/ZSR-Nr", "1472724906/R234567", "Dr. med. Herbert Ueberweise, Referrerstrasse 11, 5000 Aarau"],
    ];
    const bodyData4 = [
      ["Diagnose", "Contract", "A1; B2, A1 Text"],
    ];
    const bodyData5 = [
      ["GLN-Liste", "", "1/76345678901111 2/7634567890333"],
    ];
    const bodyData6 = [
      ["Bemerkung", "lorem ipsum"],
    ];

    const start1 = this.margin_top
      + this.fontSizeInCm(this.titleFontSize)
      + this.fontSizeInCm(this.titleFontSize) / 3;

    // Tabellen erstellen
    this.createMetaDataTable(bodyData1, start1, [1.8, 2.35, 2.5, 8, 1, 2], true);
    this.createMetaDataTable(bodyData2, undefined, [1.8, 2.35, 2.5, 3, 3.3, 4.5], false, true);
    this.createMetaDataTable(bodyData3, undefined, [1.8, 2.35, 3.9, 9.5], true);
    this.createMetaDataTable(bodyData4, undefined, [1.8, 2.35, 13.5], true);
    this.createMetaDataTable(bodyData5, undefined, [1.8, 2.35, 13.5], true);
    this.createMetaDataTable(bodyData6, undefined, [1.8, 16.2], true);
  }

  private createMetaDataTable(bodyData: any[][], startY: number | undefined, columnWidths: number[], fullBorder: boolean, leftBorderOnly: boolean = false) {
    const currentDoc = this.doc as any;
    const contentFontSize = this.contentFontSize;
    const headerFontSize = this.headerFontSize;
    const borderWidth = this.borderWidth;

    const columnStyles = columnWidths.map((width) => ({
      cellWidth: width
    }));

    // Optionen für autoTable definieren
    const options = {
      theme: 'plain',
      startY: startY ?? currentDoc.autoTable.previous.finalY,
      styles: {
        fontSize: this.headerFontSize,
        cellPadding: {top: 0.05, right: 0.02, bottom: 0.05, left: 0.13},
        lineWidth: 0,
        minCellHeight: this.fontSizeInCm(contentFontSize),
      },
      columnStyles: columnStyles,
    };

    // Tabelle erstellen
    currentDoc.autoTable({
      ...options,
      head: [],
      body: bodyData,
    });

    //Tabellen Rahmen
    currentDoc.setLineWidth(borderWidth);
    if (fullBorder) {
      // Rahmen um die gesamte Tabelle zeichnen, wenn erforderlich
      currentDoc.rect(
        this.margin_left,
        options.startY,
        currentDoc.internal.pageSize.getWidth() - this.margin_left - this.margin_right,
        currentDoc.autoTable.previous.finalY - options.startY,
        'S'
      );
    } else if (leftBorderOnly) {
      //draw a line only on the left side of the table
      currentDoc.line(
        this.margin_left,
        options.startY,
        this.margin_left,
        currentDoc.autoTable.previous.finalY,
        'S'
      );
    }
  }

  /**
   * Sets the address label on the document
   * @param address
   */
  setAddressLabel(address: { zip: string; address: string; city: string; name: string; salutation: string }) {

    const posX = this.margin_left + this.addressLabelX;
    const posY = this.margin_top + this.addressLabelY;

    const addressFontSize = 10;

    const salutation = `${address.salutation}`;
    const addressLine1 = `${address.name}`;
    const addressLine2 = `${address.address}`
    const addressLine3 = `${address.zip} ${address.city}`;
    const lineHeight = this.fontSizeInCm(addressFontSize);

    this.doc.setFontSize(addressFontSize);
    this.doc.text(salutation, posX, posY + lineHeight);
    this.doc.text(addressLine1, posX, posY + 2 * lineHeight);
    this.doc.text(addressLine2, posX, posY + 3 * lineHeight);
    this.doc.text(addressLine3, posX, posY + 4 * lineHeight);
  }

  addServicePositions(positions: {
    date: string;
    tariffNumber: number;
    tariffLabel: string;
    tariffDescription: string;
    codeOfService: string;
    reference: string;
    sessionNumber: string;
    sideDependency: string;
    quantity: number;
    taxpoint: string;
    tp_scaling_internal: string;
    tp_scaling_external: string;
    taxpoint_technical: string;
    tptl_scaling_internal: string;
    tptl_scaling_external: string;
    providerId: string;
    responsibleId: string;
    obligationFlag: string;
    vatRate: string;
    amount: string;
  }[]) {
    const currentDoc = this.doc as any;
    const contentFontSize = this.contentFontSize;

    const columnStyles = {
      date: {cellWidth: 'auto'},
      tariffNumber: {cellWidth: 'auto'},
      codeOfService: {cellWidth: 'auto'},
      reference: {cellWidth: 'auto'},
      sessionNumber: {cellWidth: 'auto'},
      sideDependency: {cellWidth: 'auto'},
      quantity: {cellWidth: 'auto'},
      taxpoint: {cellWidth: 'auto'},
      tp_scaling_internal: {cellWidth: 'auto'},
      tp_scaling_external: {cellWidth: 'auto'},
      taxpoint_technical: {cellWidth: 'auto'},
      tptl_scaling_internal: {cellWidth: 'auto'},
      tptl_scaling_external: {cellWidth: 'auto'},
      providerId: {cellWidth: 'auto'},
      responsibleId: {cellWidth: 'auto'},
      obligationFlag: {cellWidth: 'auto'},
      vatRate: {cellWidth: 'auto'},
      amount: {cellWidth: 'auto'},
    };

    const bodyData = positions.flatMap(position => [
      [
        { content: position.date, styles : { fontSize: this.contentFontSize } },
        { content: position.tariffNumber, styles : { fontSize: this.contentFontSize } },
        { content: position.codeOfService, styles : { fontSize: this.contentFontSize } },
        { content: position.reference, styles : { fontSize: this.contentFontSize } },
        { content: position.sessionNumber, styles : { fontSize: this.contentFontSize } },
        { content: position.sideDependency, styles : { fontSize: this.contentFontSize } },
        { content: position.quantity, styles : { fontSize: this.contentFontSize } },
        { content: position.taxpoint, styles : { fontSize: this.contentFontSize } },
        { content: position.tp_scaling_internal, styles : { fontSize: this.contentFontSize } },
        { content: position.tp_scaling_external, styles : { fontSize: this.contentFontSize } },
        { content: position.taxpoint_technical, styles : { fontSize: this.contentFontSize } },
        { content: position.tptl_scaling_internal, styles : { fontSize: this.contentFontSize } },
        { content: position.tptl_scaling_external, styles : { fontSize: this.contentFontSize } },
        { content: position.providerId, styles : { fontSize: this.contentFontSize } },
        { content: position.responsibleId, styles : { fontSize: this.contentFontSize } },
        { content: position.obligationFlag, styles : { fontSize: this.contentFontSize } },
        { content: position.vatRate, styles : { fontSize: this.contentFontSize } },
        { content: position.amount, styles : { fontSize: this.contentFontSize } },
      ],
      [{content:"", colSpan: 2},{content: position.tariffLabel, colSpan: 16, styles: {
        halign: 'left', valign: 'middle',
          fontStyle:'bold', fontSize: this.headerFontSize
      }}],
      [{content:"", colSpan: 2}, {content: position.tariffDescription, colSpan: 16, styles: {
        halign: 'left', valign: 'middle',
        fontStyle:'bodld', fontSize: this.headerFontSize
      }
    }]
    ]);

    const options = {
      theme: 'plain',
      startY: currentDoc.autoTable.previous.finalY + 0.1,
      styles: {
        fontSize: this.headerFontSize,
        cellPadding: {top: 0.05, right: 0.02, bottom: 0.02, left: 0.13},
        lineWidth: 0,
        minCellHeight: this.fontSizeInCm(contentFontSize),
      },
      columnStyles: columnStyles,
    };

    currentDoc.autoTable({
      ...options,
      head: [[
        "Datum", "Tarif", "Tarifziffer", "Bezugsziffer", "Si", "St", "Anzahl", "TP AL/Preis", "f AL", "TPW AL",
        "TP TL", "f TL", "TPW TL", "A", "V", "P", "M", "Betrag"
      ]],
      body: bodyData,
    });
  }

  addVatTable(param: { normalRate: number; reducedRate: number }) {
    const currentDoc = this.doc as any;

    const columnStyles = {
      code: { cellWidth: 0.7 },
      rate: { cellWidth: 0.7 },
      amount: { cellWidth: 1.4 },
      vat: { cellWidth: 0.7 },
    };
    const vatData = [
      {code: "0", rate: 0, amount: "0", vat: "0"},
      {code: "1", rate: param.reducedRate, amount: "36.53", vat: "0.99"},
      {code: "2", rate: param.normalRate, amount: "107.50", vat: "7.96"},
    ]

    const bodyData = vatData.map(data => [
      data.code,
      data.rate,
      data.amount,
      data.vat,
    ]);

    const options = {
      theme: 'plain',
      startY: currentDoc.internal.pageSize.getHeight() - 3.5, // 3.5 cm from the bottom
      margin: { left: this.margin_left + 3 }, // 3 cm from the left
      styles: {
        fontSize: this.contentFontSize - 2,
        cellPadding: { top: 0.05, right: 0.02, bottom: 0.05, left: 0.13 },
        lineWidth: 0,
        minCellHeight: this.fontSizeInCm(this.contentFontSize - 2),
      },
      columnStyles: columnStyles,
    };

    currentDoc.autoTable({
      ...options,
      head: [["Code", "Satz", "Betrag", "MwST"]],
      body: bodyData,
      tableWidth: 4.5,
    });
  }

  save(filename: string) {
    this.doc.save(filename);
  }


  private updateDocumentProperties() {
    // Set the page margins
    this.doc.setProperties(<DocumentProperties>{
      topMargin: this.margin_top,
      bottomMargin: this.margin_bottom,
      leftMargin: this.margin_left,
      rightMargin: this.margin_right
    });
  }

  private fontSizeInCm = (fontsize: number) => fontsize / 72 * 2.54
}


@Injectable({
  providedIn: 'root'
})
export class InvoiceService {
  constructor() {
  }

  generateInvoice(dokumentDaten: any) {

    const invoiceDocument = new InvoiceDocument();
    const documentType = 'Rückforderungsbeleg';
    const documentVersion = 'Release 4.5G/de';

    invoiceDocument.setTitle(documentType, documentVersion);
    invoiceDocument.setMetadata(dokumentDaten.metadata);
    invoiceDocument.setAddressLabel({
      ...dokumentDaten.metadata.patient,
      "salutation": "Herr", //TODO make dependant of sex
    })
    invoiceDocument.addServicePositions(dokumentDaten.positions);
    invoiceDocument.addVatTable(dokumentDaten.masterdata.vat);

    invoiceDocument.save("Rechnung.pdf");

  }
}
