import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../environments/environment';
import {map, Observable} from 'rxjs';
import {ServicePosition} from "../model/servicePosition.model";
import {LoadingService} from "./loading.service";
import {AuthService} from "./auth.service";
import {BaseService} from "./base.service";

@Injectable({
  providedIn: 'root'
})
export class ServiceCatalogService extends BaseService {
  private apiUrl = `${environment.apiUrl}/services`;

  constructor(private http: HttpClient,
              override loadingService: LoadingService,
              override authService: AuthService) {
    super(loadingService, authService);
  }

  searchService(searchTerm: string): Observable<ServicePosition[]> {
    return this.searchServicePosition('search', searchTerm);
  }

  searchServiceByTarif(tarifNr: string): Observable<ServicePosition[]> {
    return this.searchServicePosition('tarifNr', tarifNr);
  }

  private searchServicePosition(searchKey: string, searchValue: string) {
    return this.newRequest(
      options =>
        this.http.get<ServicePosition[]>(`${this.apiUrl}?${searchKey}=${searchValue}`, options),
      error =>
        this.handleError("Failed to load services", error)
    );
  }

  getNextPositionNumber(): Observable<string> {
    return this.searchServiceByTarif('0').pipe(
      map(services => {
        const maxPositionNr = services.length > 0
          ? Math.max(...services.map(service => Number(service.positionNr)))
          : 0;
        return (maxPositionNr + 1).toString();
      })
    );
  }

  getServiceById(id: string): Observable<ServicePosition> {
    return this.newRequest(
      options =>
        this.http.get<ServicePosition>(`${this.apiUrl}/${id}`, options),
      error =>
        this.handleError("Failed to fetch service", error)
    );
  }

  createService(service: ServicePosition): Observable<ServicePosition> {
    return this.newRequest(
      options =>
        this.http.post<ServicePosition>(this.apiUrl, service, options),
      error =>
        this.handleError("Failed to create service", error)
    )
  }


  deleteService(id: string): Observable<{}> {
    return this.newRequest(
      options =>
        this.http.delete(`${this.apiUrl}/${id}`, options),
      error =>
        this.handleError("Failed to delete service", error)
    );
  }
}
