import {Component, OnInit} from '@angular/core';
import {SubscriptionService} from "../../services/subscription.service";
import {Subscription} from "../../model/subscription.model";


@Component({
  selector: 'app-subscriptions',
  templateUrl: './subscriptions.component.html',
  styleUrls: ['./subscriptions.component.scss']
})
export class SubscriptionsComponent implements OnInit {
  subscriptions: Subscription[] = [];

  constructor(private subscriptionService: SubscriptionService){
  }

  ngOnInit(): void {
    this.loadSubscriptions();
  }

  loadSubscriptions(): void {
    this.subscriptionService.listSubscriptions().subscribe({
      next: (data) => this.subscriptions = data,
      error: (err) => console.error('Failed to load subscriptions', err)
    });
  }

  manageExternally() {
    window.open('https://billing.stripe.com/p/login/test_cN29DPeZhcjZaukaEE', '_blank');
  }

  translateInterval(interval: string): string {
  switch(interval) {
    case 'month':
      return 'Monat';
    case 'year':
      return 'Jahr';
    default:
      return interval;
  }
}

  protected readonly JSON = JSON;
}
