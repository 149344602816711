import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../environments/environment';
import {Subscription} from "../model/subscription.model";
import {Observable} from "rxjs";
import {AuthService} from "./auth.service";
import {BaseService} from "./base.service";
import {LoadingService} from "./loading.service";

@Injectable({
  providedIn: 'root'
})
export class SubscriptionService extends BaseService {

  constructor(private http: HttpClient,
              override loadingService: LoadingService,
              override authService: AuthService,
  ) {
    super(loadingService, authService);
  }


  createSubscription(): Observable<Subscription> {

    return this.newRequest(
      options =>
        this.http.post<Subscription>(`${environment.apiUrl}/subscriptions`, {}, options),
      error =>
        this.handleError("Failed to create subscription", error)
    );
  }

  listSubscriptions(): Observable<Subscription[]> {

    return this.newRequest(
      options =>
        this.http.get<Subscription[]>(`${environment.apiUrl}/subscriptions`, options),
      error =>
        this.handleError("Failed to list subscriptions", error)
    )
  }

  deleteSubscription(subscriptionId: string): Observable<any> {

    return this.newRequest(
      options =>
        this.http.delete(`${environment.apiUrl}/subscriptions/${subscriptionId}`, options),
      error =>
        this.handleError("Failed to delete subscription", error)
    )
  }
}
