<div *ngIf="!isLoggedIn" class="logIn">
    <div *ngIf="isSubscriptionSuccess" class="">
      <router-outlet></router-outlet>
    </div>
    <div *ngIf="!isSubscriptionSuccess" class="">
      <eibe-authenticator></eibe-authenticator>
    </div>
</div>
<div *ngIf="isLoggedIn" class="content">
  <section id="header">
    <app-header></app-header>
  </section>

  <section id="content">
    <router-outlet></router-outlet>
  </section>

  <section id="footer">
    <app-footer></app-footer>
  </section>
  <loading-indicator></loading-indicator>
</div>
