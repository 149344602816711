import {Component, Input, OnInit} from '@angular/core';
import { AppointmentService } from '../../services/appointment.service';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {MatDialog} from "@angular/material/dialog";
import {ConfirmDialogComponent} from "../confirm-dialog/confirm-dialog.component";
import {Appointment} from "../../model/appointment.model";

@Component({
  selector: 'app-appointments',
  templateUrl: './appointments.component.html',
  styleUrls: ['./appointments.component.scss']
})
export class AppointmentComponent implements OnInit {
  appointments: Appointment[] = [];
  selectedAppointment?: Appointment;
  isEditing: boolean = false;
  isCreating: boolean = false;
  appointmentForm: FormGroup;
  editingAppointment?: Appointment;
  @Input() selectedPatientId!: string;

  constructor(private appointmentService: AppointmentService,
              private dialog: MatDialog) {
    this.appointmentForm = new FormGroup({
      'title': new FormControl(null, Validators.required),
      'description': new FormControl(null),
      'date': new FormControl(null, Validators.required),
      'time': new FormControl(null, Validators.required),
      'duration': new FormControl(null, [Validators.required, Validators.min(1)])
    });
  }

  ngOnInit(): void {
    this.getAppointments();
  }

  private getAppointments() {
    if(this.selectedPatientId){
      this.appointmentService
        .getAppointments(this.selectedPatientId)
        .subscribe(appointments => this.appointments = appointments);
    }
  }

  selectAppointment(appointment: Appointment): void {
    this.selectedAppointment = { ...appointment };

    // Datum und Uhrzeit aus dem Date-Objekt extrahieren
    const dateTimeString = appointment.date.toISOString();
    const date = dateTimeString.slice(0, 10);  // YYYY-MM-DD
    const time = dateTimeString.slice(11, 16);  // HH:mm

    this.appointmentForm.setValue({
      title: appointment.title,
      description: appointment.description,
      date: date,
      time: time,
      duration: appointment.duration,
    });
  }


  initiateCreateAppointment(): void {
    this.isCreating = true;
    this.appointmentForm.reset();
  }

  createAppointment(): void {
    if(this.selectedPatientId === undefined) throw new Error('selectedPatient is required');
    const { time, date, ...formValues } = this.appointmentForm.value;
    const dateTimeString = `${date}T${time}`;
    const dateTime = new Date(dateTimeString);

    const newAppointment = {
      ...formValues,
      date: dateTime  // Verwenden Sie das zusammengeführte Datum und Uhrzeit
    };

    this.appointmentService
      .createAppointment(this.selectedPatientId, newAppointment)
      .subscribe({
        next: appointment => {
          this.appointments.push(newAppointment);
          this.isCreating = false;
          this.getAppointments();
        }
    });
  }

  startEditing(): void {
    this.isEditing = true;
    this.editingAppointment = <Appointment>{...this.selectedAppointment};
  }

  updateAppointment(): void {
    if (this.selectedAppointment) {
      if(this.selectedPatientId === undefined) throw new Error('selectedPatient is required');
      this.appointmentService.updateAppointment(this.selectedPatientId, this.selectedAppointment.appointmentId, this.selectedAppointment).subscribe(updatedAppointment => {
        const index = this.appointments.findIndex(a => a.appointmentId === updatedAppointment.appointmentId);
        this.appointments[index] = updatedAppointment;
        this.selectedAppointment = undefined;
      });
    }
  }

  deleteAppointment(appointment: Appointment): void {
    if(this.selectedPatientId === undefined) throw new Error('selectedPatient is required');
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: `Möchten Sie wirklich den Termin am ${new Date(appointment.date).toLocaleDateString('de-DE')} löschen?`,
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result && this.selectedPatientId) {
        this.appointmentService.deleteAppointment(this.selectedPatientId, appointment.appointmentId).subscribe(() => {
          this.appointments = this.appointments.filter(a => a.appointmentId !== appointment.appointmentId);
        });
      }
    });

  }

  cancel(): void {
    this.selectedAppointment = undefined;
    this.isCreating = false;
  }

}
