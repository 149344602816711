import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../environments/environment';
import {from, Observable, switchMap} from 'rxjs';
import {CookieService} from "ngx-cookie-service";
import {Patient} from "../model/patient.model";
import {LoadingService} from "./loading.service";
import {tap} from "rxjs/operators";
import {AuthService} from "./auth.service";
import {BaseService} from "./base.service";

@Injectable({
  providedIn: 'root'
})
export class PatientService extends BaseService {
  private apiUrl = `${environment.apiUrl}/patients`;

  constructor(private http: HttpClient,
              override loadingService: LoadingService,
              override authService: AuthService
  ) {
    super(loadingService, authService);
  }

  searchPatients(name: string): Observable<Patient[]> {
    return this.newRequest(
      options =>
        this.http.get<Patient[]>(`${this.apiUrl}?name=${name}`, options),
      error =>
        this.handleError("Failed to load patients", error)
    );
  }

  getPatientById(id: string): Observable<Patient> {
    return this.newRequest(
      options =>
        this.http.get<Patient>(`${this.apiUrl}/${id}`, options),
      error =>
        this.handleError("Failed to fetch patient", error)
    );
  }

  createPatient(patient: Patient): Observable<Patient> {
    return this.newRequest(
      options =>
        this.http.post<Patient>(this.apiUrl, patient, options),
      error =>
        this.handleError("Failed to create patient", error)
    );
  }

  updatePatient(id: string, patient: Patient): Observable<Patient> {
    return this.newRequest(
      options =>
        this.http.put<Patient>(`${this.apiUrl}/${id}`, patient, options),
      error =>
        this.handleError("Failed to update patients", error)
    );
  }

  deletePatient(id: string): Observable<{}> {
    return this.newRequest(
      options =>
        this.http.delete(`${this.apiUrl}/${id}`, options),
      error =>
        this.handleError("Failed to delete patient", error)
    );
  }

}
