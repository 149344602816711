import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from "../environments/environment";
import {CookieService} from "ngx-cookie-service";
import {from, Observable, of, Subject, switchMap} from "rxjs";
import {fetchAuthSession, getCurrentUser, signOut} from 'aws-amplify/auth';
import {CookieStorage, Hub} from 'aws-amplify/utils';
import {Amplify} from "aws-amplify";
import {cognitoUserPoolsTokenProvider} from 'aws-amplify/auth/cognito';
import {Router} from "@angular/router";

interface AuthResponse {
  access_token: string;
  refresh_token: string;
  id_token: string;
  expires_in: number;
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  // Add a new Subject that emits a value when the login is successful
  loginSuccess$ = new Subject<void>();
  logoutSuccess$ = new Subject<void>();

  constructor(private http: HttpClient,
              private router: Router,
              private cookieService: CookieService) {
    Amplify.configure({
      Auth: {
        Cognito: {
          userPoolId: environment.userPoolId,
          userPoolClientId: environment.userPoolWebClientId,
          loginWith: {
            oauth: {
              domain: environment.oauthDomain,
              scopes: ['email', 'openid', 'profile'],
              redirectSignIn: environment.oauthRedirectSignIn,
              redirectSignOut: environment.oauthRedirectSignOut,
              responseType: 'token'
            }
          },
          userAttributes: {
            email: { required: true},
            given_name: { required: true},
            family_name: { required: true},
            phone_number: { required: true},
            address: { required: true},
            preferred_username: { required: false},
          },
        }
      }
    });
    cognitoUserPoolsTokenProvider.setKeyValueStorage(new CookieStorage());
    Hub.listen('auth', ({ payload }) => {
      switch (payload.event) {
        case 'signedIn':
          console.log('user have been signedIn successfully.', payload);
          this.loginSuccess$.next();
          break;
        case 'signedOut':
          console.log('user have been signedOut successfully.', payload);
          this.logoutSuccess$.next()
          break;
        case 'tokenRefresh':
          console.log('auth tokens have been refreshed.',payload);
          break;
        case 'tokenRefresh_failure':
          console.log('failure while refreshing auth tokens.',payload);
          break;
        case 'signInWithRedirect':
          console.log('signInWithRedirect API has successfully been resolved.',payload);
          break;
        case 'signInWithRedirect_failure':
          console.log('failure while trying to resolve signInWithRedirect API.',payload);
          break;
      }
    });
  }

  async getHttpOptions() {
    // const token = this.cookieService.get('token'); // Adjust 'token' if your cookie has a different name
    const token= await this.getToken();
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      })
    };
  }

  logout() {
    signOut().then(() => {
      // this.cookieService.delete('token', '/');
      this.logoutSuccess$.next()  // Emit a value when the logout  is successful
    })
  }

  login(): Observable<boolean> {
      return from(this.isLoggedIn()).pipe(
        switchMap(isLoggedIn => {
          if (isLoggedIn) {
            this.loginSuccess$.next();  // Emit a value when the login is successful
            return of(true);
          } else {
            this.router.navigateByUrl('/login');
            // window.location.href = '/login';
            return of(false);
          }
        })
      );
  }

  async getToken() {
    const {accessToken, idToken} = (await fetchAuthSession()).tokens ?? {};
    return accessToken?.toString();
  }

  async isLoggedIn(): Promise<boolean> {
    const {accessToken, idToken} = (await fetchAuthSession()).tokens ?? {};
    const token = accessToken;
    if (!token || !token.payload || !token.payload.exp) {
      return false;
    }
    try {
      const currentTime = Date.now() / 1000;
      return token.payload.exp >= currentTime;
    } catch (e) {
      return false;
    }
  }

  async currentAuthenticatedUser() {
    try {
      return getCurrentUser();
    } catch (err) {
      console.log(err);
      return undefined;
    }
  }

  async currentSession()  {
    try {
      return  fetchAuthSession();
    } catch (err) {
      console.log(err);
      return undefined;
    }
  }

  handleAuthenticationError(error: any): boolean {
    if (error.status === 401) {
      this.cookieService.delete('token');
      this.login();
      return true;
    }
    return false;
  }
}
