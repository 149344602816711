import {Component, Input, OnInit} from '@angular/core';
import {SessionService} from '../../services/session.service';
import {FormArray, FormBuilder, FormGroup} from '@angular/forms';
import {Session} from "../../model/session.model";
import {MatDialog} from "@angular/material/dialog";
import {ConfirmDialogComponent} from "../confirm-dialog/confirm-dialog.component";
import {ServiceCatalogService} from "../../services/serviceCatalog.service";
import {ServicePosition} from "../../model/servicePosition.model";
import {InvoiceService} from "../../services/invoice.service";
import {PatientService} from "../../services/patient.service";
import {DatePipe} from "@angular/common";
import { forkJoin } from 'rxjs';


@Component({
  selector: 'app-sessions',
  templateUrl: './sessions.component.html',
  styleUrls: ['./sessions.component.scss']
})
export class SessionComponent implements OnInit {

  @Input() selectedPatientId?: string;
  sessions: Session[] = [];
  selectedSession?: Session;
  isCreating: boolean = false;
  sessionForm: FormGroup;
  sessionStart: Date = new Date();
  durationDisplay: string = '00:00';
  private page: number = 0;
  private pageSize: number = 20;
  isRunning: boolean = false;
  private timerId?: number;
  servicePositions: ServicePosition[] = [];

  constructor(private sessionService: SessionService,
              private invoiceService: InvoiceService,
              private patientService: PatientService,
              private serviceCatalogService: ServiceCatalogService,
              private dialog: MatDialog,
              private fb: FormBuilder,
              private datePipe : DatePipe,
  ) {
    this.sessionForm = this.fb.group({
      notes: [''],
      duration: [''],
      services: this.fb.array([])
    });
  }

  ngOnInit(): void {
    this.getSessions();
    this.serviceCatalogService.searchService('').subscribe(servicePositions => {
      this.servicePositions = servicePositions.sort((a, b) => {
        if (a.tarifNr === b.tarifNr) {
          return a.positionNr.localeCompare(b.positionNr);
        }
        return a.tarifNr.localeCompare(b.tarifNr);
      }).map(service => {
        return {
          ...service,
          serviceId: service.serviceId,
        }
      });
    });

  }

  getSessions(): void {
    if (this.selectedPatientId) {
      this.sessionService.getSessions(this.selectedPatientId, this.page, this.pageSize).subscribe(sessions => {
        this.sessions = sessions;
      });
    }
  }

  selectSession(session: Session): void {
    this.selectedSession = session;
    this.sessionForm.setValue({
      notes: session.notes,
      duration: session.duration,
      services: session.services.map(service => this.fb.group({
        serviceId: [service.serviceId],
        amount: [service.amount]
      }))
    });
  }

  initiateCreateSession(): void {
    this.isCreating = true;
    this.sessionForm.reset();
    this.startSession();
  }

  createSession(): void {
    this.stopSession();
    const newSession = this.sessionForm.value;
    this.sessionService
      .createSession(this.selectedPatientId, newSession)
      .subscribe({
          next: session => {
            this.sessions.push(newSession);
            this.isCreating = false;
            this.getSessions();
          },
        }
      );
  }

  updateSession(): void {
    if (!this.selectedSession) return;
    this.sessionService.updateSession(this.selectedPatientId, this.selectedSession.sessionId, this.sessionForm.value).subscribe(() => {
      this.getSessions();
      this.selectedSession = undefined;
    });
  }

  deleteSession(session: Session): void {
    if (!this.selectedPatientId) return;
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: `Möchten Sie wirklich die Sitzung vom ${new Date(session.date).toLocaleDateString('de-DE')} löschen?`,
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result && this.selectedPatientId) {
        this.sessionService.deleteSession(this.selectedPatientId, session.sessionId).subscribe(() => {
          this.getSessions();
        });
      }
    });
  }

  startSession(): void {
    if (!this.isRunning) {
      this.isRunning = true;
      this.sessionStart = new Date();
      this.timerId = window.setInterval(() => this.updateDurationDisplay(), 1000);
    }
  }

  stopSession(): void {
    if (this.isRunning) {
      this.isRunning = false;
      const now = new Date();
      const duration = Math.ceil((now.getTime() - this.sessionStart.getTime()) / 60000); // convert milliseconds to minutes
      this.sessionForm.patchValue({
        duration: this.sessionForm.value.duration + duration
      });
    }
  }

  updateDurationDisplay(): void {
    if (this.isRunning) {
      const now = new Date();
      const duration = now.getTime() - this.sessionStart.getTime();
      const minutes = Math.floor(duration / 60000);
      const seconds = Math.floor((duration % 60000) / 1000);
      this.durationDisplay = `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    }
  }

  cancel(): void {
    this.selectedSession = undefined;
    this.isCreating = false;
  }

  get services() {
    return this.sessionForm.get('services') as FormArray;
  }

  addService() {
    this.services.push(this.fb.group({
      serviceId: [this.servicePositions[0].serviceId],
      amount: [1]
    }));
  }

  removeService(index: number) {
    this.services.removeAt(index);
  }

  createInvoice(session: Session) {

    const masterData = {
      vat : {
        reducedRate: 2.5,
        normalRate: 8.1,
      },
      rates : {
        baseRate: 9.00
      }
    }

    const userProfile = {
      name: "Example AG",
      address: "Beispielstrasse 128",
      zipCode: "5210",
      city: "Windisch",
    }


    if (this.selectedPatientId) {

      this.patientService.getPatientById(this.selectedPatientId).subscribe(patient => {

        const data = {
          metadata: {
            //TODO fetch from user's master profile data
            provider: {
              name: userProfile.name,
              address: userProfile.address,
              zipCode: userProfile.zipCode,
              city: userProfile.city,
            },
            patient: {
              name: patient.firstname,
              firstName: patient.lastname,
              address: patient.address,
              zipCode: "ZIP",
              city: "STADT",
              birthdate: patient.birthdate,
              sex: "M", //TODO
              sv_nr: patient.sv_nr,
            }
          },
          masterdata : {
            vat : masterData.vat,
            rates: masterData.rates
          },
          positions: [{}],
        }

        const serviceObservables = session.services.map(service => this.serviceCatalogService.getServiceById(service.serviceId));

        forkJoin(serviceObservables).subscribe(servicePositions => {
          data.positions = servicePositions.map(servicePosition => this.mapServicePositionToInvoicePosition(session, servicePosition));
          console.log(`Generating invoice with data`, data);
          this.invoiceService.generateInvoice(data);
        });
      })
    }

  }

  private mapServicePositionToInvoicePosition(session: Session, service: ServicePosition) {
    console.log(`Mapping service position ${JSON.stringify(service)}`)
    return {
      date: this.datePipe.transform(session.date, 'dd.MM.yyyy'),
      tariffNumber: service.tarifNr,
      codeOfService: service.positionNr,
      tariffLabel: service.label.de,
      tariffDescription: service.description.de,
      reference: "", // This needs to be provided or fetched from somewhere
      sessionNumber: "1", // This needs to be provided or fetched from somewhere
      sideDependency: "", // This needs to be provided or fetched from somewhere
      quantity: service.amount ?? 1,
      taxpoint: "9.57", // This needs to be provided or fetched from somewhere
      tp_scaling_internal: "1.0", // This needs to be provided or fetched from somewhere
      tp_scaling_external: "0.89", // This needs to be provided or fetched from somewhere
      taxpoint_technical: "8.19", // This needs to be provided or fetched from somewhere
      tptl_scaling_internal: "1.0", // This needs to be provided or fetched from somewhere
      tptl_scaling_external: "0.89", // This needs to be provided or fetched from somewhere
      providerId: "1", // This needs to be provided or fetched from somewhere
      responsibleId: "2", // This needs to be provided or fetched from somewhere
      obligationFlag: "1", // This needs to be provided or fetched from somewhere
      vatRate: "0", // This needs to be provided or fetched from somewhere
      amount: "10.00", // This needs to be provided or fetched from somewhere
    };
  }
}
