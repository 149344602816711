export const DEFAULT_TARIFF = '999';
export const TARIFF_590 = '590';
export interface TarifNode {
  tarifNr: string,
  tarifName: MultiLangString,
  chapterNr: string;
  label: MultiLangString;
  chapters: Chapter[];
  positions: Position[];
}
export interface Tarif extends TarifNode {
}
export interface Chapter extends TarifNode {
}
export interface Position {
  tarifNr: string;
  positionNr: string;
  label: MultiLangString;
  description: MultiLangString;
  unit: TarifUnit,
  valid: boolean
  validFrom?: Date;
  validUntil?: Date;
}
export interface MultiLangString {
  de: string;
  fr: string;
  it: string;
}
export enum TarifUnit {
  'pauschal'= 'pauschal',
  '5min' = '5min',
  'stk' = 'stk',
  'occ' = 'occ',
}
