<div class="container patient-list">
  <div *ngIf="!selectedPatient">
    <div class="search-container">
      <input #searchInput type="text" placeholder="Klientenname" (input)="searchPatients(searchInput.value)">
      <button (click)="searchPatients(searchInput.value)">Suchen</button>
      <button (click)="searchPatients('*')">Alle anzeigen</button>
    </div>
    <div class="button-container">
      <button (click)="startCreating()">Neuen Klient erfassen</button>
    </div>
    <div class="result-container" *ngIf="patients.length > 0">
      <table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Alter</th>
            <th>SV NR</th>
            <th>Adresse</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let patient of patients" (click)="selectPatient(patient)" class="patient-box">
            <td>{{ patient.firstname }} {{ patient.lastname }}</td>
            <td>{{ calculateAge(patient.birthdate) }}</td>
            <td>{{ patient.sv_nr }}</td>
            <td>{{ patient.address }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <div *ngIf="selectedPatient && !isEditing">
    <h2>Klienten Details</h2>
    <p>Vorname: {{ selectedPatient.firstname }}</p>
    <p>Nachname: {{ selectedPatient.lastname }}</p>
    <p>Geburtsdatum: {{ selectedPatient.birthdate | date }}</p>
    <p>AHV Nr: {{ selectedPatient.sv_nr }}</p>
    <p>Adresse: {{ selectedPatient.address }}</p>
    <p>Email: {{ selectedPatient.email }}</p>
    <p>Telefon: {{ selectedPatient.phone }}</p>
    <div class="button-container">
      <button (click)="unselectPatient()">Schliessen</button>
      <button (click)="startEditing()">Bearbeiten</button>
      <button (click)="deletePatient()">Löschen</button>
      <button (click)="startNewSession()">Neue Sitzung starten</button>
      <button (click)="startNewAppointment()">Neuen Termin vereinbaren</button>
    </div>

    <app-sessions [selectedPatientId]="selectedPatient.patientId"></app-sessions>
    <app-appointments [selectedPatientId]="selectedPatient.patientId"></app-appointments>
  </div>

  <div class="form-container" *ngIf="isCreating || isEditing">
    <h2>Klient {{isCreating ? 'Erfassen' : 'Bearbeiten'}}</h2>
    <form #patientForm="ngForm" (ngSubmit)="savePatient(patientForm.value)">
      <input type="text" name="firstname" [(ngModel)]="editingPatient.firstname" placeholder="Vorname" required>
      <input type="text" name="lastname" [(ngModel)]="editingPatient.lastname" placeholder="Nachname" required>
      <input type="date" name="birthdate" [(ngModel)]="editingPatient.birthdate" placeholder="Geburtsdatum" required>
      <input type="text" name="sv_nr" [(ngModel)]="editingPatient.sv_nr" placeholder="AHV Nr" required>
      <input type="text" name="address" [(ngModel)]="editingPatient.address" placeholder="Adresse">
      <input type="email" name="email" [(ngModel)]="editingPatient.email" placeholder="Email">
      <input type="tel" name="phone" [(ngModel)]="editingPatient.phone" placeholder="Telefon">
      <div class="button-container">
        <button class="button cancel" type="button" (click)="cancelEditOrCreate()">Abbrechen</button>
        <button class="button submit" type="submit">Speichern</button>
      </div>
    </form>
  </div>
</div>
