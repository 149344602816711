import {Component, OnInit} from '@angular/core';
import {AuthenticatorService, translations} from '@aws-amplify/ui-angular';
import {I18n} from "aws-amplify/utils";
import {SignUpAttribute} from "@aws-amplify/ui/dist/types/types/authenticator/attributes";
import {signUp} from "@aws-amplify/auth";

@Component({
  selector: 'eibe-authenticator',
  templateUrl: 'authenticator.component.html',
  styleUrls: ['authenticator.component.scss'],
})
export class AuthenticatorComponent implements OnInit{
  public signUpAttributes : SignUpAttribute[] = [
    'given_name', 'family_name', 'phone_number', 'address'
  ];
  public formFields = {
    signUp: {
      username: {
        label: 'Benutzername (Email)',
        placeholder: 'Geben Sie Ihre E-Mail-Adresse ein',
        order:1,
        required: false,
      },
      given_name: {
        order: 2,
        required: true,
      },
      family_name: {
        order: 3,
        required: true,
      },
      phone_number : {
        order: 4,
        required: true,
        dialCode: "+41",
        dialCodeList: ['+41', '+423', '+49', '+43', '+33', '+39'],
      },
      password: {
        order: 5
      },
      confirm_password: {
        order: 6
      },
    },
  };

  constructor(public authenticator: AuthenticatorService) {

    I18n.putVocabularies(translations);
    const dict = {
      de: {
        'Given Name': 'Vorname',
        'Family Name': 'Nachname',
        'Enter your Username': 'Geben Sie Ihren Benutzernamen ein',
        'Enter your Given Name': 'Geben Sie Ihren Vornamen ein',
        'Enter your Family Name': 'Geben Sie Ihren Nachnamen ein',
        'Enter your Phone Number': 'Geben Sie Ihre Telefonnummer ein',
      },
    };
    I18n.putVocabularies(dict);
    I18n.setLanguage('de');
  }

  ngOnInit(): void {

  }

  services = {
    async validateCustomSignUp(formData: Record<string, string>) {
      let  {acknowledgement, ...rest} = formData;
      if (!acknowledgement) {
        return {
          acknowledgement: 'Sie müssen den Nutzungsbedingungen zustimmen',
        };
      }
      return undefined;
    },

    async handleSignUp(formData: Record<string, any>) {
      let { username, password, options } = formData;
      options.email = username;
      return signUp({
        username,
        password,
        options,
      });
    },
  };


}
