import {NestedTreeControl} from '@angular/cdk/tree';
import {Component, OnInit} from '@angular/core';
import {
  MultiLangString,
  Position,
  Tarif, TARIFF_590,
  TarifNode,
  TarifUnit
} from '../../model/tarif.model';
import {
  TarifService,
} from '../../services/tarif.service';
import {MatTreeNestedDataSource} from "@angular/material/tree";
import jwt_decode from "jwt-decode";
import {JWT} from "../../model/jwt.model";
import {AuthService} from "../../services/auth.service";
import {ServiceCatalogService} from "../../services/serviceCatalog.service";
import {ServicePosition} from "../../model/servicePosition.model";

@Component({
  selector: 'app-tarife',
  templateUrl: './tarif.component.html',
  styleUrls: ['./tarif.component.scss']
})
export class TarifComponent implements OnInit {

  searchValue: string = '';
  loggedIn = false;
  isBillable = false;
  originalData: Tarif = <Tarif>{};
  data: Tarif = <Tarif>{};

  treeControl = new NestedTreeControl<TarifNode>(node => node.chapters);
  dataSource = new MatTreeNestedDataSource<TarifNode>();
  tarif590Positions: ServicePosition[] = [];

  constructor(private authService: AuthService,
              private tarifService: TarifService,
              private serviceCatalogService: ServiceCatalogService) {
  }

  ngOnInit(): void {
    this.loadTarifData();
    this.loadServicePositions();
    this.refreshToken();
    this.authService.loginSuccess$.subscribe(() => this.refreshToken());
    this.authService.logoutSuccess$.subscribe(() => {
      this.refreshToken();
      // window.location.reload();
    });
  }

  private async refreshToken() {
    this.loggedIn =  await  this.authService.isLoggedIn();
    if (this.loggedIn) {

      //TODO provide billable info from authService
      let cookieValue = await this.authService.getToken();
      if(!cookieValue) {
        console.log("no cookie found")
        return;
      }
      let decodedCookie = jwt_decode<JWT>(cookieValue);
      if (decodedCookie['cognito:groups']?.includes("billable") ?? false) {
        this.isBillable = true;
      } else {
        console.log("has no active subscription")
      }
    }
  }

  async loadTarifData() {
    this.originalData = await this.tarifService.loadTarif();
    this.dataSource.data = [this.originalData];
    console.log(this.originalData);
  }

  async loadServicePositions() {
    this.serviceCatalogService.searchServiceByTarif(TARIFF_590).subscribe((data) => {
      this.tarif590Positions = data;
    });
  }

  onSearchChange(searchValue: string): void {
    this.searchValue = searchValue;
    this.filterTree();
  }

  toggleServicePosition(position: Position) {
    if (this.isServicePositionRegistered(position)) {
      this.disableTarifPosition(position);
    } else {
      this.enableTarifPosition(position);
    }
  }

  enableTarifPosition(position: Position) {
    const newService = <ServicePosition>{
      serviceId: '',
      tarifNr: position.tarifNr,
      positionNr: position.positionNr,
      unit: position.unit,
      label: position.label,
      description: position.description,
      valid: position.valid
    }
    this.serviceCatalogService.createService(newService).subscribe(() => {
      this.loadServicePositions();
    });
  }

  disableTarifPosition(position: Position) {
    //find the servicePosition from the tarif590Positions, get the serviceId and delete it
    const servicePosition = this.tarif590Positions.find(service => service.positionNr === position.positionNr);
    if (servicePosition) {
      this.serviceCatalogService.deleteService(servicePosition.serviceId).subscribe(() => {
        this.loadServicePositions();
      });
    }
  }

  isServicePositionRegistered(position: Position): boolean {
    //iterate over service positions and check if the position is already registered
    return this.tarif590Positions.some(service => service.positionNr === position.positionNr);
  }

  toggleAllPositions(checked: boolean): void {
    this.toggleAll(this.data, checked);
  }

  toggleAll(node: TarifNode, checked: boolean): void {
    node.positions.forEach(position => {
      if (checked && !this.isServicePositionRegistered(position)) {
        this.enableTarifPosition(position);
      } else if (!checked && this.isServicePositionRegistered(position)) {
        this.disableTarifPosition(position);
      }
    });
  }

  allPositionsEnabled(node : TarifNode): boolean {
    //check for all positions of the node if they are enabled/contained in the tarif590Positions
    return node.positions.every(position => this.isServicePositionRegistered(position));
  }

  filterTree(): void {
    if (!this.searchValue) {
      this.dataSource.data = [this.originalData];
      this.treeControl.dataNodes = this.dataSource.data;
      return;
    }

    const filteredData = this.filterNode(this.originalData);
    this.dataSource.data = [filteredData];
    this.treeControl.dataNodes = this.dataSource.data;
    this.treeControl.expandAll();
  }

  filterNode(node: TarifNode): TarifNode {
    let filteredNode = <TarifNode>{...node, chapters: [], positions: []};

    node.chapters.forEach(chapter => {
      const filteredChapter = this.filterNode(chapter);
      if (filteredChapter.positions.length > 0 || filteredChapter.chapters.length > 0) {
        filteredNode.chapters.push(filteredChapter);
      }
    });

    node.positions.forEach(position => {
      if (this.isPositionMatch(position)) {
        filteredNode.positions.push(position);
      }
    });

    return filteredNode;
  }

  isPositionMatch(position: Position): boolean {
    const searchValueLower = this.searchValue.toLowerCase();
    return position.positionNr.toLowerCase().includes(searchValueLower)
      || position.label.de.toLowerCase().includes(searchValueLower)
      || position.description.de?.toLowerCase().includes(searchValueLower);
  }

  hasChild = (_: number, node: TarifNode) => !!node.chapters && node.chapters.length > 0;

  private isMultiLangString(obj: any): obj is MultiLangString {
    return obj && typeof obj.de === 'string' && typeof obj.fr === 'string' && typeof obj.it === 'string';
  }

  private isTarifUnit(obj: any): obj is TarifUnit {
    return Object.values(TarifUnit).includes(obj);
  }

  private isDate(obj: any): obj is Date {
    return obj instanceof Date && !isNaN(obj.getTime());
  }


  isPosition(obj: any): obj is Position {
    return obj &&
      typeof obj.positionNr === 'string' &&
      this.isMultiLangString(obj.label) &&
      this.isMultiLangString(obj.description) &&
      this.isTarifUnit(obj.unit) &&
      typeof obj.valid === 'boolean' &&
      (obj.validFrom === undefined || this.isDate(obj.validFrom)) &&
      (obj.validUntil === undefined || this.isDate(obj.validUntil));
  }


}
