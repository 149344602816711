// ServiceCatalog.component.ts
import {Component, OnInit} from '@angular/core';
import {ServiceCatalogService} from '../../services/serviceCatalog.service';
import {ServicePosition} from '../../model/servicePosition.model';
import {MatDialog} from "@angular/material/dialog";
import {ConfirmDialogComponent} from "../confirm-dialog/confirm-dialog.component";
import {DEFAULT_TARIFF, MultiLangString, TarifUnit} from "../../model/tarif.model";
import {FormControl, FormGroup} from "@angular/forms";

@Component({
  selector: 'app-service-catalog',
  templateUrl: './service-catalog.component.html',
  styleUrls: ['./service-catalog.component.scss']
})
export class ServiceCatalogComponent implements OnInit {
  services: ServicePosition[] = [];
  selectedService: ServicePosition | null = null;
  isCreating = false; // Add this line
  searchName: string = '';
  tarifUnits = Object.values(TarifUnit);
  servicePositionForm: FormGroup;

  constructor(private serviceCatalogService: ServiceCatalogService,
              private dialog: MatDialog) {
    this.servicePositionForm = new FormGroup({
      positionNr: new FormControl(''),
      unit: new FormControl(TarifUnit.pauschal),
      label: new FormControl(''),
      description: new FormControl(''),
    });
  }

  ngOnInit(): void {
    this.getServices();
  }

  getServices(): void {
    this.serviceCatalogService.searchService(this.searchName).subscribe(services => {
      this.services = services;
    });
  }

  selectService(service: ServicePosition): void {
    this.selectedService = service;
  }

  createService(): void {
    const formData = this.servicePositionForm.value;
    const newService = <ServicePosition>{
      serviceId: '',
      tarifNr: DEFAULT_TARIFF,
      positionNr: formData.positionNr,
      unit: formData.unit,
      label: <MultiLangString>{de: formData.label, fr: '', it: ''},
      description: <MultiLangString>{de: formData.description, fr: '', it: ''},
      valid: true
    }
    if (!newService.positionNr) {
      this.serviceCatalogService.getNextPositionNumber().subscribe(nextPositionNr => {
        newService.positionNr = nextPositionNr;
        this.serviceCatalogService.createService(newService).subscribe(() => {
          this.getServices();
        });
      });
    } else {
      this.serviceCatalogService.createService(newService).subscribe(() => {
        this.getServices();
      });
    }
  }

  deleteService(servicePosition: ServicePosition): void {

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: `Möchten Sie wirklich Leistungsposition ${servicePosition.tarifNr}/${servicePosition.positionNr} löschen?`,
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.serviceCatalogService.deleteService(servicePosition.serviceId).subscribe(() => {
          this.getServices();
        });
      }
    });
  }

  initiateCreateServicePosition(): void {
    this.isCreating = true;
    this.serviceCatalogService.getNextPositionNumber().subscribe(nextPositionNr => {
      this.servicePositionForm.reset({
        positionNr: nextPositionNr,
        unit: TarifUnit.pauschal,
        label: '',
        description: '',
      });
    });
  }

  cancel(): void {
    this.isCreating = false;
  }

  protected readonly TarifUnit = TarifUnit;
}
