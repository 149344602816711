<amplify-authenticator [formFields]="formFields"
                       [signUpAttributes]="signUpAttributes"
                       [services]="services"
                       [hideSignUp]="true"
>
  <ng-template amplifySlot="header">
    <div style="padding: var(--amplify-space-large); text-align: center">
      <img
        class="amplify-image"
        alt="Eibe logo"
        src="assets/eibe-branch.jpg"
      />
    </div>
  </ng-template>

  <ng-template amplifySlot="sign-in-header">
    <h3
      class="amplify-heading"
      style="padding: var(--amplify-space-xl) 0 0 var(--amplify-space-xl)"
    >
      Melden Sie sich bei Ihrem Konto an
    </h3>
  </ng-template>

  <ng-template amplifySlot="sign-in-footer">
    <div style="padding: var(--amplify-space-large); text-align: center">
      <button
        (click)="authenticator.toForgotPassword()"
        class="amplify-button amplify-field-group__control"
        data-fullwidth="false"
        data-size="small"
        data-variation="link"
        type="button"
        style="font-weight: normal"
      >
        Passwort zurücksetzen
      </button>
    </div>
  </ng-template>

  <ng-template amplifySlot="footer">
    <div style="padding: var(--amplify-space-large); text-align: center">
      <p class="amplify-text" style="color: var(--amplify-colors-neutral-80)">
        © Alle Rechte vorbehalten
      </p>
    </div>
  </ng-template>
</amplify-authenticator>
