import {NgModule, isDevMode} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {FormsModule, ReactiveFormsModule} from '@angular/forms'; // FormsModule importieren
import {MatTreeModule} from '@angular/material/tree';
import {CdkTreeModule} from '@angular/cdk/tree';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {ConfirmDialogComponent} from 'src/components/confirm-dialog/confirm-dialog.component';
import {AuthenticatorComponent} from 'src/components/authenticator/authenticator.component';
import {FooterComponent} from 'src/components/footer/footer.component';
import {HeaderComponent} from 'src/components/header/header.component';
import {HomePageComponent} from 'src/components/home-page/home-page.component';
import {LoadingIndicatorComponent} from 'src/components/loading-indicator/loading-indicator.component';
import {SubscriptionsComponent} from 'src/components/subscriptions/subscriptions.component';
import {PatientListComponent} from 'src/components/patients/patient-list.component';
import {SessionComponent} from 'src/components/sessions/sessions.component';
import {TarifComponent} from 'src/components/tarife/tarif.component';
import {AppointmentComponent} from 'src/components/appointments/appointments.component';
import {HttpClientModule} from '@angular/common/http';
import {RoundPipe} from './pipes/round.pipe';
import {MatDialogModule} from "@angular/material/dialog";
import {MatButtonModule} from "@angular/material/button";
import {DatePipe, NgIf} from "@angular/common";
import {MatIconModule, MatIconRegistry} from "@angular/material/icon";
import {MatTableModule} from "@angular/material/table";
import {MatTooltipModule} from "@angular/material/tooltip";
import {ServiceCatalogComponent} from "../components/service-catalog/service-catalog.component";
import {MatSlideToggleModule} from "@angular/material/slide-toggle";
import {CalendarComponent} from "../components/calendar/calendar.component";
import {CalendarModule, DateAdapter} from 'angular-calendar';
import {adapterFactory} from 'angular-calendar/date-adapters/date-fns';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {I18n} from 'aws-amplify/utils';
import {AmplifyAuthenticatorModule, translations} from '@aws-amplify/ui-angular';
import {environment} from '../environments/environment';
import {SubscriptionSuccessComponent} from "../components/subscriptions/subscription-success.component";
import {MatListModule} from "@angular/material/list";
import {MatLineModule} from "@angular/material/core";
import { ServiceWorkerModule } from '@angular/service-worker';


//amplify i18n
I18n.putVocabularies(translations);
I18n.setLanguage('de');

@NgModule({
  declarations: [
    AppComponent,
    AuthenticatorComponent,
    FooterComponent,
    HeaderComponent,
    CalendarComponent,
    ConfirmDialogComponent,
    HomePageComponent,
    LoadingIndicatorComponent,
    AppointmentComponent,
    PatientListComponent,
    SessionComponent,
    SubscriptionsComponent,
    SubscriptionSuccessComponent,
    SessionComponent,
    ServiceCatalogComponent,
    TarifComponent,
    RoundPipe,
  ],
  imports: [
    BrowserModule,
    CdkTreeModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    HttpClientModule,
    MatDialogModule,
    MatButtonModule,
    MatIconModule,
    MatTableModule,
    MatTooltipModule,
    MatTreeModule,
    NgIf,
    MatIconModule,
    MatSlideToggleModule,
    BrowserAnimationsModule,
    AmplifyAuthenticatorModule,
    CalendarModule.forRoot({provide: DateAdapter, useFactory: adapterFactory}),
    MatListModule,
    MatLineModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
  ],
  exports: [
    RoundPipe,
  ],
  providers: [DatePipe],
  bootstrap: [AppComponent]
})
export class AppModule {

  constructor(matIconRegistry: MatIconRegistry) {
  }

}
