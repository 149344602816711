<div class="container sessions">
  <h2>Sitzungen</h2>
  <div *ngIf="selectedSession || isCreating">
    <h3>{{ isCreating ? 'Neue Sitzung' : 'Sitzung Bearbeiten' }}</h3>
    <form [formGroup]="sessionForm">
      <div>
        <label for="notes">Notizen</label>
        <textarea id="notes" formControlName="notes"></textarea>
      </div>
      <div>
        <label for="duration">Dauer</label>
        <span *ngIf="isRunning">{{ durationDisplay }}</span>
        <input id="duration" type="number" formControlName="duration" [value]="durationDisplay">
      </div>
      <div>
        <button type="button" (click)="startSession()">Start</button>
        <button type="button" (click)="stopSession()">Stop</button>
      </div>
      <div formArrayName="services">
        <div *ngFor="let service of services.controls; index as i" [formGroupName]="i">
          <select formControlName="serviceId" class="your-css-class">
            <option *ngFor="let servicePosition of servicePositions" [value]="servicePosition.serviceId" selected>
              Tarif {{ servicePosition.tarifNr }} / Position {{ servicePosition.positionNr }}
              :{{ servicePosition.label.de }}
            </option>
          </select>
          <label for="amount">Menge</label>
          <input type="number" id="amount" formControlName="amount">
          <button type="button" (click)="removeService(i)">Entfernen</button>
        </div>
      </div>
      <div>
        <button type="button" (click)="addService()">Leistung Hinzufügen</button>
      </div>
      <div>
        <button type="submit" (click)="isCreating ? createSession() : updateSession()">Speichern</button>
        <button type="button" (click)="cancel()">Abbrechen</button>
      </div>
    </form>
  </div>

  <div *ngFor="let session of sessions">
    <h3>{{ session.date | date:'dd.MM.yyyy HH:mm' }} ({{ session.duration | round }} Minuten)</h3>
    <p>{{ session.notes }}</p>
    <button (click)="selectSession(session)">Bearbeiten</button>
    <button (click)="deleteSession(session)">Löschen</button>
    <button type="button" (click)="createInvoice(session)">Rechnung</button>
  </div>
  <button (click)="initiateCreateSession()">Neue Sitzung starten</button>
</div>
