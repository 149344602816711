import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {HomePageComponent} from 'src/components/home-page/home-page.component';
import { PatientListComponent } from 'src/components/patients/patient-list.component';
import {SubscriptionsComponent} from "../components/subscriptions/subscriptions.component";
import {TarifComponent} from "../components/tarife/tarif.component";
import {ServiceCatalogComponent} from "../components/service-catalog/service-catalog.component";
import {CalendarComponent} from "../components/calendar/calendar.component";
import {AuthenticatorComponent} from "../components/authenticator/authenticator.component";
import {SubscriptionSuccessComponent} from "../components/subscriptions/subscription-success.component";

const routes: Routes = [
  {
    path: '',
    component: HomePageComponent,
  },
  {
    path: 'subscribed',
    component: SubscriptionSuccessComponent,
  },
  {
    path: 'subscriptions',
    component: SubscriptionsComponent
  },
  {
    path: 'patients',
    component: PatientListComponent
  },
  {
    path: 'tarif',
    component: TarifComponent
  },
  {
    path: 'service-catalog',
    component: ServiceCatalogComponent
  },
  {
    path: 'calendar',
    component: CalendarComponent
  },
  {
    path: 'login',
    component: AuthenticatorComponent
  },
  {
    path: 'logout',
    component: AuthenticatorComponent
  },
  {
    path: '**',
    redirectTo: '',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
