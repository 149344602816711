<div class="header">
  <button class="menu-btn" (click)="toggleMenu()">☰</button>
  <div class="menu-items menu-closed">
    <ul>
      <li><a routerLink="" class="logo" (click)="closeMenu()">Einfach Behandeln</a></li>
      <li><a routerLink="" class="home navlink" (click)="closeMenu()">Startseite</a></li>
      <li><a routerLink="subscriptions" class="navlink" *ngIf="loggedIn" (click)="closeMenu()">Abonnement</a></li>
      <li><a routerLink="patients" class="navlink" *ngIf="isBillable" (click)="closeMenu()">Klienten</a></li>
      <li><a routerLink="tarif" class="navlink" *ngIf="loggedIn" (click)="closeMenu()">Tarif</a></li>
      <li><a routerLink="service-catalog" class="navlink" *ngIf="isBillable" (click)="closeMenu()">Leistungen</a></li>
      <li><a routerLink="calendar" class="navlink" *ngIf="isBillable" (click)="closeMenu()">Termine</a></li>
    </ul>
  </div>

  <div class="header-right">
    <button class="login" (click)="this.logout()" *ngIf="loggedIn">Abmelden</button>
  </div>
</div>
