import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from "../environments/environment";
import {Session} from 'src/model/session.model';
import {LoadingService} from "./loading.service";
import {AuthService} from "./auth.service";
import {BaseService} from "./base.service";

@Injectable({
  providedIn: 'root'
})
export class SessionService extends BaseService {

  constructor(private http: HttpClient,
              override loadingService: LoadingService,
              override authService: AuthService) {
    super(loadingService, authService);
  }

  private getSessionsUrl(patientId: string) {
    return `${environment.apiUrl}/patients/${patientId}/sessions`
  }

  getSessions(patientId: string | undefined, page: number, pageSize: number): Observable<any> {
    if (!patientId) throw new Error('Patient ID is required to create a session');
    const params = {
      page,
      pageSize
    };

    return this.newRequest(
      options =>
        this.http.get<Session[]>(this.getSessionsUrl(patientId), {...options, params}),
      error =>
        this.handleError("Failed to load sessions", error)
    )
  }

  getSession(patientId: string | undefined, sessionId: string): Observable<Session> {
    if (!patientId) throw new Error('Patient ID is required to create a session');
    return this.newRequest(
      options =>
        this.http.get<Session>(`${this.getSessionsUrl(patientId)}/${sessionId}`, options),
      error => this.handleError("Failed to load session", error)
    );
  }

  createSession(patientId: string | undefined, session: any): Observable<any> {
    if (!patientId) throw new Error('Patient ID is required to create a session');
    return this.newRequest(
      options =>
        this.http.post<Session>(this.getSessionsUrl(patientId), session, options),
      error =>
        this.handleError("Failed to update session", error)
    );
  }

  updateSession(patientId: string | undefined, sessionId: string, session: Session): Observable<any> {
    if (!patientId) throw new Error('Patient ID is required to create a session');
    console.log(`Updating session patient=${patientId} session=${sessionId}`, session);
    return this.newRequest(
      options =>
        this.http.put<Session>(`${this.getSessionsUrl(patientId)}/${sessionId}`, session, options),
      error =>
        this.handleError("Failed to update session", error)
    );
  }

  deleteSession(patientId: string | undefined, sessionId: string): Observable<any> {
    if (!patientId) throw new Error('Patient ID is required to create a session');
    return this.newRequest(
      options =>
        this.http.delete(`${this.getSessionsUrl(patientId)}/${sessionId}`, options),
      error =>
        this.handleError("Failed to delete session", error)
    );
  }
}
