<div class="container">
  <h1>
    Willkommen bei Einfach-Behandeln!
  </h1>
  <p>
    Vielen Dank, dass Sie sich für unseren Service entschieden haben.
    Sie erhalten in Kürze eine E-Mail mit Ihrer Registrierungsbestätigung.
  </p>
  <p>
    Nach Erhalt der Bestätigung können Sie sich
    <a routerLink="/login">anmelden</a> und auf die Applikation zugreifen.
  </p>
  <p>
    Sie können Ihr Abonnement jederzeit über das
    <a href="{{ environment.stripeConfig.customerPortal }}" target="_blank">Stripe Kundenportal</a> verwalten.
  </p>
  <div class="footer">
    Bei Fragen wenden Sie sich bitte an unseren Kundensupport: <a href="mailto:support@einfach-behandeln.ch">support&#64;einfach-behandeln.ch</a>
  </div>
</div>
