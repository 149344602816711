import { Component } from '@angular/core';
import {environment} from "../../environments/environment";

@Component({
  selector: 'app-subscription-success',
  templateUrl: './subscription-success.component.html',
  styleUrls: ['./subscription-success.component.scss']
})
export class SubscriptionSuccessComponent {
  protected readonly environment = environment;
}
