import {Injectable} from '@angular/core';
import * as XLSX from 'xlsx';
import {Chapter, Tarif, TarifUnit} from "../model/tarif.model";

interface TarifDataRow {
  TarifNr: string;
  TarifName_De: string;
  TarifName_Fr: string;
  TarifName_It: string;
  Kapitelziffer: string;
  Kapitelbezeichung_De: string;
  Kapitelbezeichung_Fr: string;
  Kapitelbezeichung_It: string;
  Unterkapitelziffer: string;
  UnterkapitelBezeichung_De: string;
  UnterkapitelBezeichung_Fr: string;
  UnterkapitelBezeichung_It: string;
  Abrechnungsziffer: string;
  AbrechnungszifferText_De: string;
  AbrechnungszifferText_Fr: string;
  AbrechnungszifferText_It: string;
  Beschreibung_De: string;
  Beschreibung_Fr: string;
  Beschreibung_It: string;
  DatumVon: number;
  DatumBis: number;

}

@Injectable({
  providedIn: 'root'
})
export class TarifService {
  private jsonData: Tarif = <Tarif>{};

  constructor() { }

  async loadTarif(): Promise<Tarif> {
    const workbook = await this.loadSpreadsheet("assets/tarif-590.xlsx");
    const worksheet = workbook.Sheets["Tarif 590 V4"];
    //cast result to TarifDataRow
    const data : string[] = XLSX.utils.sheet_to_json(worksheet, { header: 1 ,rawNumbers: true, raw: true});
    const dataRows = data.map(this.mapDataRow);
    this.jsonData = this.parseTarif(dataRows);
    return this.jsonData;
  }

  private async loadSpreadsheet(file: string): Promise<XLSX.WorkBook> {
    const response = await fetch(file);
    const arrayBuffer = await response.arrayBuffer();
    return XLSX.read(arrayBuffer, { type: 'buffer' });
  }

  private mapDataRow(row : any) : TarifDataRow {
    const stringIfDefined = (input:any, formatNumber = false) => {
      if (input === undefined || input === null) {
        return undefined;
      }

      if (formatNumber && typeof input === 'number') {
        return String(input).replace(',', '.');
      }

      return String(input);
    };
    return <TarifDataRow>{
      TarifNr: stringIfDefined(row[0]),
      TarifName_De: stringIfDefined(row[1]),
      TarifName_Fr: stringIfDefined(row[2]),
      TarifName_It: stringIfDefined(row[3]),
      Kapitelziffer: stringIfDefined(row[4]),
      Kapitelbezeichung_De: stringIfDefined(row[5]),
      Kapitelbezeichung_Fr: stringIfDefined(row[6]),
      Kapitelbezeichung_It: stringIfDefined(row[7]),
      Unterkapitelziffer: stringIfDefined(row[8], true),
      UnterkapitelBezeichung_De: stringIfDefined(row[9]),
      UnterkapitelBezeichung_Fr: stringIfDefined(row[10]),
      UnterkapitelBezeichung_It: stringIfDefined(row[11]),
      Abrechnungsziffer: stringIfDefined(row[12]),
      AbrechnungszifferText_De: stringIfDefined(row[13]),
      AbrechnungszifferText_Fr: stringIfDefined(row[14]),
      AbrechnungszifferText_It: stringIfDefined(row[15]),
      Beschreibung_De: stringIfDefined(row[16]),
      Beschreibung_Fr: stringIfDefined(row[17]),
      Beschreibung_It: stringIfDefined(row[18]),
      DatumVon: row[19],
      DatumBis: row[20]
    };
  }



  // Beispiel-Implementierung der Transformationslogik
  parseTarif(data: TarifDataRow[]): Tarif {
    const today = new Date();
    let root = <Tarif>{
      tarifNr: '',
      tarifName: {de: '', fr: '', it: ''},
      chapterNr: '',
      label: {de: '', fr: '', it: ''},
      chapters: [],
      positions: [],
    };

    // Helferfunktionen
    const isValidDate = (from: Date, to: Date) => today >= from && today <= to;

    let currentChapter : Chapter | undefined;
    let currentSubChapter : Chapter | undefined;

    data.forEach((row, index) => {
      // Erste Zeile mit Tarif-Informationen oder Leerzeilen überspringen
      if (index === 0 || index === 1 || !row.TarifName_De) {
        return;
      }

      //first match sets the overall tarifNr
      if(!root.tarifNr && row.TarifNr){
        this.setTarifName(root, row);
      }

      if(!currentChapter
        || (row.Kapitelziffer && !currentChapter.chapterNr.startsWith(row.Kapitelziffer))){
        currentChapter = this.newChapter(row);
        root.chapters.push(currentChapter);
        currentSubChapter = undefined; //reset subChapter
      }
      if( (!currentSubChapter && row.Unterkapitelziffer)
        || (currentSubChapter && row.Unterkapitelziffer && !currentSubChapter.chapterNr.startsWith(row.Unterkapitelziffer))){
        currentSubChapter = this.newChapter(row);
        currentChapter.chapters.push(currentSubChapter);
      }

      //first match sets the chapter tarifNr
      if(!currentChapter.tarifNr && row.TarifNr){
        currentChapter.tarifNr = row.TarifNr;
      }
      //first match sets the chapter tarifNr
      if(currentSubChapter && !currentSubChapter.tarifNr && row.TarifNr){
        currentSubChapter.tarifNr = row.TarifNr;
      }

      // Positionen
      if (row.Abrechnungsziffer) {
        const validFrom = this.parseExcelDate(row.DatumVon);
        const validUntil = this.parseExcelDate(row.DatumBis);
        let position = {
          tarifNr: row.TarifNr,
          positionNr: row.Abrechnungsziffer,
          label: { de: row.AbrechnungszifferText_De, fr: row.AbrechnungszifferText_Fr, it: row.AbrechnungszifferText_It },
          description: { de: row.Beschreibung_De, fr: row.Beschreibung_Fr, it: row.Beschreibung_It },
          unit: this.parseUnit(row.AbrechnungszifferText_De),
          validFrom,
          validUntil,
          valid: isValidDate(validFrom, validUntil)
        };
        if(currentSubChapter){
          currentSubChapter.positions.push(position);
        } else {
          currentChapter.positions.push(position);
        }
      }
    });

    return root;
  }

  private setTarifName(root: Tarif, row: TarifDataRow) {
    root.tarifNr = row.TarifNr;
    root.tarifName.de = row.TarifName_De;
    root.tarifName.fr = row.TarifName_Fr;
    root.tarifName.it = row.TarifName_It;
    root.chapterNr = row.TarifNr;
    root.label.de = row.TarifName_De;
    root.label.fr = row.TarifName_Fr;
    root.label.it = row.TarifName_It;
  }

  private newChapter(row: TarifDataRow) {
      return <Chapter>{
        tarifNr: row.TarifNr,
        tarifName: {
          de: row.TarifName_De,
          fr: row.TarifName_Fr,
          it: row.TarifName_It
        },
        chapterNr: row.Kapitelziffer ?? row.Unterkapitelziffer,
        label: {
          de: row.Kapitelbezeichung_De ?? row.UnterkapitelBezeichung_De,
          fr: row.Kapitelbezeichung_Fr ?? row.UnterkapitelBezeichung_Fr,
          it: row.Kapitelbezeichung_It ?? row.UnterkapitelBezeichung_It
        },
        positions: [],
        chapters: []
      };
  }

  private parseUnit(description: string) : TarifUnit{
    const units = [{
      matcher: /pro\s+5\s+Min/i,
      unit: TarifUnit["5min"]
    }, {
      matcher: /pro\s+Stück/i,
      unit: TarifUnit.stk
    }, {
      matcher: /pro\s+Anwendung/i,
      unit: TarifUnit.occ
    }];
    return units.find(unit => description.match(unit.matcher))?.unit || TarifUnit.pauschal;
  }

  private parseExcelDate(excelDate: number): Date {
    // Excel-Datumsbasis: 30. Dezember 1899
    const excelBaseDate = new Date(Date.UTC(1899, 11, 30));

    // Umrechnung: Excel-Tage zu Millisekunden
    const millisecondsPerDay = 24 * 60 * 60 * 1000;
    return new Date(excelBaseDate.getTime() + excelDate * millisecondsPerDay);
  }


}
