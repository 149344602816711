<button (click)="manageExternally()">Abonnements verwalten</button>
<mat-list>
  <mat-list-item *ngFor="let sub of subscriptions">
    <h3 matListItemTitle> {{ sub.plan.product.name }} </h3>
    <p matListItemLine> {{ sub.plan.amount / 100 }} {{ sub.plan.currency.toUpperCase() }} / {{ translateInterval(sub.plan.interval) }} </p>
    <p matListItemLine> Status: {{ sub.status }} </p>
    <p matListItemLine> Startdatum: {{ sub.startDate | date }} </p>
    <p matListItemLine *ngIf="sub.trialStartDate && sub.trialEndDate"> Testperiode: {{ sub.trialStartDate | date }} - {{ sub.trialEndDate | date }} </p>
  </mat-list-item>
</mat-list>
