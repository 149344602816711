import {Component, OnInit} from '@angular/core';
import {AuthService} from "../services/auth.service";
import jwt_decode from "jwt-decode";
import {JWT} from "../model/jwt.model";
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {filter} from "rxjs";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css',
  '../../node_modules/@aws-amplify/ui-angular/theme.css'
  ]
})
export class AppComponent implements OnInit {
  title = 'web-ui';

  isLoggedIn = false;
  isSubscriptionSuccess = false;
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService
  ) {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event ) => {
      let navigationEnd = event as NavigationEnd;
      this.isSubscriptionSuccess = navigationEnd.url.includes('/subscribed');
    });
  }

  ngOnInit() {
    this.refreshToken();
    this.authService.loginSuccess$.subscribe(() => this.refreshToken());
    this.authService.logoutSuccess$.subscribe(() => this.refreshToken());
  }
  private async refreshToken() {
    this.isLoggedIn = await this.authService.isLoggedIn();
  }
}
