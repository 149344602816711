<div class="search-container">
  <input [(ngModel)]="searchValue" (ngModelChange)="onSearchChange($event)" type="text" placeholder="Suche">
  <button (click)="onSearchChange(searchValue)">Suchen</button>
</div>

<mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="example-tree">
  <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
    <div class="mat-tree-node">
      <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'Toggle ' + node.tarifNr">
        <mat-icon class="mat-icon-rtl-mirror">
          {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
        </mat-icon>
        {{ node.chapterNr}} - {{ node.label.de}}
      </button>
      {{node.name}}
    </div>
    <div [class.example-tree-invisible]="!treeControl.isExpanded(node)" role="group">
      <ng-container matTreeNodeOutlet>
        <div *ngIf="node.positions?.length > 0">
          <div class="toggle-all-container" *ngIf="isBillable">
            <mat-slide-toggle [checked]="allPositionsEnabled(node)" (change)="toggleAll(node, $event.checked)">Alle Positionen</mat-slide-toggle>
          </div>
          <table mat-table [dataSource]="node.positions" class="mat-elevation-z1">
            <ng-container matColumnDef="toggle">
              <th mat-header-cell *matHeaderCellDef>Leistungskatalog</th>
              <td mat-cell *matCellDef="let position" >
                <div *ngIf="isBillable">
                  <mat-slide-toggle *ngIf="isBillable" [checked]="isServicePositionRegistered(position)" (change)="toggleServicePosition(position)">
                  </mat-slide-toggle>
                </div>
              </td>
            </ng-container>
            <ng-container matColumnDef="positionNr">
              <th mat-header-cell *matHeaderCellDef>Abrechnungsziffer</th>
              <td mat-cell *matCellDef="let position">{{position.positionNr}}</td>
            </ng-container>
            <ng-container matColumnDef="description">
              <th mat-header-cell *matHeaderCellDef>Beschreibung</th>
              <td mat-cell *matCellDef="let position">
                <p class="label">
                  {{position.label.de}}
                </p>
                <p class="description">
                  {{position.description.de}}
                </p>
              </td>
            </ng-container>
            <ng-container matColumnDef="unit">
              <th mat-header-cell *matHeaderCellDef>Einheit</th>
              <td mat-cell *matCellDef="let position">{{position.unit}}</td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="['toggle', 'positionNr', 'description', 'unit']"></tr>
            <tr mat-row *matRowDef="let row; columns: ['toggle', 'positionNr',  'description', 'unit']" class="{{ row.valid ? '' : 'hidden' }}"></tr>
          </table>
        </div>
      </ng-container>
    </div>
  </mat-nested-tree-node>
</mat-tree>
