import {AppointmentService} from '../../services/appointment.service';
import {PatientService} from "../../services/patient.service";
import {Component, OnInit,} from '@angular/core';
import {addMinutes, isSameDay, isSameMonth, startOfDay,} from 'date-fns';
import {Subject} from 'rxjs';
import {CalendarEvent, CalendarEventTimesChangedEvent, CalendarView,} from 'angular-calendar';


//from https://mattlewis-github.com/angular-calendar/#/kitchen-sink

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss']
})
export class CalendarComponent implements OnInit {
  view: CalendarView = CalendarView.Month;
  viewDate: Date = new Date();
  events: CalendarEvent[] = [];
  activeDayIsOpen: boolean = true;

  constructor(
    private appointmentService: AppointmentService,
    private patientService: PatientService,
  ) {
  }

  ngOnInit(): void {
    this.fetchAppointments();
  }

  fetchAppointments(): void {
    this.patientService.searchPatients('').subscribe(patients => {
      patients.forEach(patient => {
        this.appointmentService.getAppointments(patient.patientId)
          .subscribe(appointments => {
            appointments.forEach(appointment => {
              console.log("minutes", addMinutes(new Date(appointment.date), appointment.duration));
              this.events.push({
                id: appointment.appointmentId,
                start: new Date(appointment.date),
                end: addMinutes(new Date(appointment.date), appointment.duration),
                title: appointment.title,
                allDay: false,
              });
            });
            this.refresh.next();
          });
      });
    });
  }

  refresh = new Subject<void>();

  dayClicked({date, events}: { date: Date; events: CalendarEvent[] }): void {
    if (isSameMonth(date, this.viewDate)) {
      this.activeDayIsOpen = !((isSameDay(this.viewDate, date) && this.activeDayIsOpen) || events.length === 0);
      this.viewDate = date;
    }
  }

  eventTimesChanged({
                      event,
                      newStart,
                      newEnd,
                    }: CalendarEventTimesChangedEvent): void {
    this.events = this.events.map((iEvent) => {
      if (iEvent === event) {
        return {
          ...event,
          start: newStart,
          end: newEnd,
        };
      }
      return iEvent;
    });
    this.handleEvent('Dropped or resized', event);
  }

  handleEvent(action: string, event: CalendarEvent): void {
  }


  setView(view: CalendarView) {
    this.view = view;
  }


  closeOpenMonthViewDay() {
    this.activeDayIsOpen = false;
  }

  protected readonly CalendarView = CalendarView;
}
