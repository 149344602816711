<div class="container service-catalog">
  <div class="search-container">
    <input type="text" [(ngModel)]="searchName" placeholder="Search services" (keyup)="getServices()">
  </div>

  <table>
    <tr>
      <th>TarifNr</th>
      <th>PositionNr</th>
      <th>Label</th>
      <th>Description</th>
      <th>Unit</th>
      <th>Valid</th>
      <th>Actions</th>
    </tr>
    <tr *ngFor="let service of services">
      <td>{{ service.tarifNr }}</td>
      <td>{{ service.positionNr }}</td>
      <td>{{ service.label.de }}</td>
      <td>{{ service.description.de }}</td>
      <td>{{ service.unit }}</td>
      <td>{{ service.valid }}</td>
      <td>
        <button (click)="deleteService(service)">Löschen</button>
        <button (click)="selectService(service)">Auswählen</button>
      </td>
    </tr>
  </table>

  <button (click)="initiateCreateServicePosition()">Neuen Eintrag erfassen</button>

  <div class="form-container" *ngIf="isCreating">
    <!-- Form to create a new service -->
    <form *ngIf="isCreating" [formGroup]="servicePositionForm">
      <label for="positionNr">PositionNr:</label>
      <input type="text" id="positionNr" formControlName="positionNr" placeholder="PositionNr">

      <label for="label">Name:</label>
      <input type="text" id="label" formControlName="label" placeholder="Name">

      <label for="description">Beschreibung:</label>
      <input type="text" id="description" formControlName="description" placeholder="Beschreibung">

      <label for="unit">Einheit:</label>
      <select id="unit" formControlName="unit">
        <option *ngFor="let unit of tarifUnits" [value]="unit">{{ unit }}</option>
      </select>

      <button type="button" (click)="cancel()">Abbrechen</button>
      <button type="submit" (click)="createService()">Leistungsposition erfassen</button>
    </form>
  </div>

  <!-- Display selected service details -->
  <div *ngIf="selectedService">
    <h2>Selected Service</h2>
    <p>TarifNr: {{ selectedService.tarifNr }}</p>
    <p>PositionNr: {{ selectedService.positionNr }}</p>
    <p>Name: {{ selectedService.label.de }}</p>
    <p>Beschreibung: {{ selectedService.description.de }}</p>
    <p>Einheit: {{ selectedService.unit }}</p>
    <p>Gültig: {{ selectedService.valid }}</p>
  </div>
</div>
